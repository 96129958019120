import React from 'react';
import { bool, object } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';

import { ALL_CARDS_ROUTE } from 'constants/navigation';
import { LOYALTY_CARD_NAV } from 'constants/font-awesome';

import useStyles from './styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/three'));
const Page = loadable(() => import('components/page'));
const ApplicationFooter = loadable(() => import('components/footer/application-footer'));
const AccountView = loadable(() => import('views/account'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedAccountPage = ({ subscription, pricingModels }) => {
  const styles = useStyles();

  const { cmsJson: { pages: { account } } } = useStaticQuery(
    graphql`
      query  { cmsJson { pages { account { title message } } } }`,
  );

  const { title, message } = account;
  return (
      <Page
        title="Your Account"
        description={message}>
        <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          {title}
        </Typography>
        <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
          {message}
        </Typography>
        <AccountView subscription={subscription} pricingModels={pricingModels}/>
        <ApplicationFooter
          navItems={[
            { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
          ]}
          currentItem="Account"
          hasSubscription/>
      </Page>
  );
};

SuspendedAccountPage.propTypes = {
  loading: bool,
  subscription: object.isRequired,
  pricingModels: object.isRequired,
};

export default SuspendedAccountPage;
